<template>
  <div :id="id" :class="customClass"></div>
</template>

<script>
import * as d3 from "d3";
import $ from "jquery";
import { sleep, randomNum, demicalLength } from "@/utils/utils.js";
export default {
  props: ["id", "customClass"],
  data() {
    return {
      svg: null,
      svgLineTotalLength: 0,
      svgLineTotal: 0,
    };
  },
  methods: {
    async playOut() {
      if (this.svg == null) {
        return;
      }
      this.svg.transition().duration(100).style("opacity", "0");
    },
    async init() {
      await this.getSettings();
    },
    getSettings() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          resolve();
        });
      });
    },



    //k线成交量
    async kVolumeChart(
      data,
      {
        // curveName = "curveMonotoneX",
        // curveName = 'curveNatural',
        curveName = "curveLinear",
        marginTop = 40, // the top margin, in pixels
        marginRight = 50, // the right margin, in pixels
        marginBottom = 30, // the bottom margin, in pixels
        marginLeft = 40, // the left margin, in pixels
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        xDomain, // an array of (ordinal) x-values
        xRange = [marginLeft, width - marginRight], // [left, right]
        yDomain, // [ymin, ymax]
        yRange = [height - marginBottom, marginTop + 20], // [bottom, top]
        yFormat = ",f", // a format specifier string for the y-axis
        duration = 400, //动画持续时长
        delay = 40, //元素之间间隔时长
        ease = "easeQuadOut", //元素之间间隔时长
        xPadding = 0.3,
        xTicks = false,
      } = {}
    ) {
      this.duration = duration;
      // data = data.reverse();
      console.log(data);
      const dataLen = data.length;
      const curve = d3[curveName];
      const X = data.map((d) => d.label);
      const yMax = d3.max(data, (d) => Math.abs(d.volume));

      if (xDomain === undefined) xDomain = X;
      if (yDomain === undefined) yDomain = [0, yMax];

      if (data.length < 60) {
        xRange = [marginLeft, ((width - marginRight - marginLeft) * data.length) / 50 + marginLeft];
      }

      const xScale = d3.scaleBand(xDomain, xRange).padding(xPadding);
      const yScale = d3.scaleLinear(yDomain, yRange);

      const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      this.svg = svg;

      const yTick = [1, 2, 3];
      const xTick = Array.from(Array(50).keys()).slice(1);
      const yTickSpace = (height - marginTop - marginBottom) / (yTick.length - 1);
      const xTickSpace = (width - marginLeft - marginRight) / (xTick.length - 1);
      //画网格
      const drawGrid = () => {
        const rowLine = svg
          .append("g")
          .attr("class", "y_tick_group")
          .selectAll("line")
          .data(yTick)
          .enter()
          .append("line")
          .attr("x1", (d, i) => marginLeft)
          .attr("y1", (d, i) => marginTop + yTickSpace * i)
          .attr("x2", (d, i) => marginLeft)
          .attr("y2", (d, i) => marginTop + yTickSpace * i)
            .attr("class", (d, i) => {
                return "y_tick"+i
            })
          .attr("stroke", "#F0F0F0")
          .attr("stroke-width", 2)
          .attr("stroke-dasharray", 5)
          .attr("opacity", 0)
          .transition()
          .duration(duration / 2)
          .attr("x2", (d, i) => width - marginLeft)
          .attr("y2", (d, i) => marginTop + yTickSpace * i);

        const columnLine = svg
          .append("g")
          .attr("class", "x_tick_group")
          .selectAll("line")
          .data(xTick)
          .enter()
          .append("line")
          .attr("x1", (d, i) => {
            if(i == Math.ceil(50*3/5-1)){
              return marginLeft + xTickSpace * i-xTickSpace/5
            }else{
              return marginLeft + xTickSpace * i
            }
            })
          .attr("y1", (d, i) => height - marginBottom)
          .attr("x2", (d, i) => {
            if(i == Math.ceil(50*3/5-1)){
              return marginLeft + xTickSpace * i-xTickSpace/5
            }else{
              return marginLeft + xTickSpace * i
            }
            })
          .attr("y2", (d, i) => height - marginBottom)
            .attr("class", (d, i) => {
                return "x_tick"+i
            })
          .attr("stroke", "#F0F0F0") //F0F0F0
          .attr("stroke-width", 2) 
          .attr("stroke-dasharray", 5)
          .attr("opacity",  (d, i) => {
                if(i == Math.ceil(50*3/5-1)){
                    return 1
                }else {
                    return 0
                }
                })
          .transition()
          .duration(duration / 2)
          .attr("x2", (d, i) => marginLeft + xTickSpace * i)
          .attr("y2", (d, i) => marginTop);
      };
      drawGrid();



      //画柱子
      const drawBar = () => {
        const barGroup = svg
          .append("g")
          .attr("class", "k_bars_group")
          .selectAll("rect")
          .data(data)
          .enter()
          .append("rect")
          .attr("class", (d, i) => (d.volume > 0 ? "k_bar k_bar_positive" : "k_bar k_bar_negative"))
          .attr("x", (d, i) => xScale(d.label))
          .attr("y", (d, i) => yScale(Math.abs(d.volume)))
          .attr("width", xScale.bandwidth())
          .attr("height", (d, i) => yScale(0) - yScale(Math.abs(d.volume)))
          .attr("fill", (d) => (d.volume > 0 ? "#FF3B30" : "#18AA0C"))
          .attr("stroke", (d) => (d.volume > 0 ? "#FF3B30" : "#18AA0C"))
          .attr("stroke-width", 1)
          .attr("opacity", 0)
          .transition()
          .delay((d, i) => i * delay)
          .duration(duration)
          .ease(d3[ease])
          .attr("opacity", 1);
      };
      drawBar();




          // 画阴影区域
    const rectangular=svg 
        .append("g")     
        .selectAll("rect")        
        .data(xTick.slice(0,1))
        .enter() 
        .append("rect")
        .attr("class", "future_gray_rect")
        .attr("x", marginLeft + xTickSpace * Math.ceil(50*3/5-1)-xTickSpace/5)
        .attr("y", marginTop)
        .attr("height", height - marginBottom-marginTop)
        .attr("width", xTickSpace * Math.ceil(50*2/5))
        .attr('color', '#E5E5E5')

      $("#" + this.id).html(svg.node());
    },

 
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less" scoped>
// @import "./index.less";
</style>
