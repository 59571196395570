<template>
    <div id="similarKChart">
        <div class="pages hidden" id="similarKPages">
            <page-title id="similarKChartText" ref="refPageTitle"></page-title>
            <div ref="refSimilarKHolder" v-if="isSimilarKVisible">
                <div class="simulationType" v-if='isSimulation'>
                    <div class="type1" style="width:60%">近期走势</div>
                    <div class="type2" style="width:40%">模拟走势</div>
                </div>
                <k-chart-similar ref="refSimilarKLine2" id="similarKLine2" customClass="k_charts_similar" />
                <k-chart ref="refSimilarKLine" id="similarKLine" customClass="k_charts" />
                <sub-chart ref="refSimilarKSub" id="similarKSub" customClass="sub_charts" />
                <div class='anadesc' v-if='similarType'><span>{{similarsecname}}（</span><span class="anandesc_color">{{ currentData}}</span><span>）</span></div>
                <div class='desc' v-if='!similarType'>{{ currentData}}</div>
            </div>             
            <div class="no_data" v-if="isNotData">
                暂无数据
            </div>       
        </div>        
    </div>
    
</template>
<script>
import $ from "jquery";
import { sleep, setRem } from "@/utils/utils.js";
import pageTitle from "@/businessComp/charts/pageTitle/index.vue";
import KChartSimilar from "./kChartSimilar.vue";
import KChart from "./kChart.vue";
import subChart from "./subChart.vue";


export default {
    data:function(){
        return{
            isSimilarKVisible:false,
            currentData:"",
            similarType:false,
            similarsecname:"",
            isSimulation:false, 
            isNotData: false

        }
    },
    components:{
        pageTitle,
        KChart,
        subChart,
        KChartSimilar
    },
    methods:{
        preview(commandData) {
            setRem(1080);
            this.show(commandData)
        },
        async hide(){
            return new Promise(async (resolve,reject)=>{
                this.$refs.refPageTitle.hide();
                await sleep(200);
                this.isSimilarKVisible = false;
                this.isSimulation = false;
                this.similarType = false;
                this.isNotData = false;
                resolve();
            });
        },
        async show(commandData){
            if (commandData) {
                this.isSimilarKVisible = false;
                this.isSimulation = false;
                this.similarType = false;
                this.isNotData = false;
                $("#similarKPages").removeClass("hidden");
                this.$refs.refPageTitle.show(commandData);
                this.$nextTick(async ()=>{
                    await sleep(300);
                    if(["模拟走势"].indexOf(commandData.type) > -1){
                        this.isSimulation =true
                        this.handleSimilarK(commandData);
                    }else{
                        this.similarType =true
                        this.handleCommon(commandData);
                    }
                });
            } else {
                await sleep(300);
                this.isNotData = true
            }
        },
/*==============================================*/
        handleSimilarK(commandData){
            this.currentData=commandData.data.data.chartDesc
            this.isSimilarKVisible = true;
            this.$nextTick(()=>{
                this.renderSimilarK(commandData);
            });
        },
        renderSimilarK(commandData) {
            let width = this.$refs.refSimilarKHolder.clientWidth;
            let kData = commandData.data.data.chartData;
            let subData = commandData.data.data.chartData.kData.subdata;
            this.$refs.refSimilarKLine2.KLineChart(kData, {
                width: width,
                height: width * 0.40,
                marginTop: 0,
                marginBottom: 30,
                marginLeft: 0,
                marginRight: 0,
                duration: 40,
                delay: 6,
                totalLength:30,
                ease: "easeCubicInOut",
            });
            this.$refs.refSimilarKSub.kVolumeChart(subData, {
                width: width,
                height: width * 0.20,
                marginTop: 20,
                marginBottom: 20,
                marginLeft: 0,
                marginRight: 0,
                duration: 40,
                delay: 6,
                ease: "easeCubicInOut",
            });
        },

/*==============================================*/
        handleCommon(commandData){
            this.currentData=commandData.data.data.chartDesc
            this.similarsecname=commandData.data.data.similarsecname
            this.isSimilarKVisible = true;
            this.$nextTick(()=>{
                this.renderCommon(commandData);
            });
        },
        renderCommon(commandData) {
            let width = this.$refs.refSimilarKHolder.clientWidth;
            let kData = commandData.data.data.chartData.kdate;
            let subData = commandData.data.data.chartData.subdata;
            console.log(subData,'subdatasubdata');
            this.$refs.refSimilarKLine.KLineChart(kData, {
                width: width,
                height: width * 0.48,
                marginTop: 20,
                marginBottom: 30,
                marginLeft: 0,
                marginRight: 0,
                duration: 40,
                delay: 6,
                totalLength:50,
                ease: "easeCubicInOut",
            });
            this.$refs.refSimilarKSub.kVolumeChart(subData, {
                width: width,
                height: width * 0.25,
                marginTop: 20,
                marginBottom: 20,
                marginLeft: 0,
                marginRight: 0,
                duration: 40,
                delay: 6,
                ease: "easeCubicInOut",
            });
        },

    },
    async mounted(){
        window.hide = this.hide;
        window.show = this.preview;
        this.$bus.$on("chartExit",()=>{
            this.hide();
        })
    },

};



</script>
<style lang="less" scoped>
@import "index.less";
</style>